import Head from 'next/head'
import { Inter } from '@next/font/google'
import Layout from "../components/Layout/layout";

const inter = Inter({ subsets: ['latin'] })

export default function Home() {


    return (
    <Layout>
      <Head>
        <title>Configuratore Jdee</title>
        <meta name="description" content="Generated by s1r" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </Layout>
  )
}
